import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import ExpertiseHeroComponent from '../../components/expertise-page/ExpertiseHeroComponent';
import ContactFormComponent from '../../components/contact-us-page/ContactFormComponent';
import WhyChoosePRIMEComponent from '../../components/expertise-page/WhyChoosePRIMEComponent';

const StyledDivWrapper = styled.div`
  overflow: hidden;

  .bg-image {
    min-height: 300px;
    @media only screen and (max-width: 426px) {
      min-height: 200px;
    }
  }

  h3 {
    margin-bottom: 0px;
  }

  .strong-description {
    margin: 50px 0px;
    text-align: center;
  }

  .row-title,
  b {
    color: ${props => props.theme.primeBluePalette.original};
  }

  .section-wrapper {
    padding: 50px 0px 0px 0px;
    > last-child {
      padding: 50px 0px 50px 0px;
    }
  }

  li {
    margin-bottom: 0;
  }

  .section-wrapper {
    p,
    li,
    b {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .get-report-link {
    background-color: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.primeYellowPalette.original};
    padding: 12px 20px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 20px;
  }

  .contact-form {
    position: relative;
    max-width: 1440px;
    margin: 50px auto 0px;
    > .ant-row {
      position: relative;
      z-index: 1;
    }

    ::after {
      content: '';
      top: 0%;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      background-color: #f2f2f5;
      clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
      @media only screen and (max-width: 500px) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }

    & .ant-row:first-child {
      padding: 1.5rem 0px;
      margin-bottom: 0px !important;
    }

    .icon-list-item {
      display: flex;
      align-items: center;
      > :first-child {
        font-size: 15px;
      }
      > :last-child {
        margin-left: calc(1rem * 0.3);
      }
    }

    .contact-content-wrap {
      h4,
      h5 {
        color: ${props => `${props.theme.blackPalette.original}`};
      }
    }

    @media only screen and (max-width: 576px) {
      clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
    }
  }
`;

function OurExpertiseStarterPage({ location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiServices(
        filter: { visible: { eq: true }, service_type: { eq: "Starter" } }
      ) {
        nodes {
          strapiId
          category
          description
          content
          service_client_details
          service_name
          service_type
          strapiId
          tags
          url_slug
          visible
          thumb_photo
          banner_photo
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Our Expertise - Starter"
        description="As a local real estate firm with on-ground presence, we are at the vantage point to be your effective partner and help you to start in the real industry whether you are an individual looking to invest or a business group with core businesses not related to real estate and want to venture to RE industry for the first time."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <StyledDivWrapper>
        <div>
          <ExpertiseHeroComponent />
          <WhyChoosePRIMEComponent />

          <Row type="flex" justify="center">
            <Col sm={22} xs={22} md={22} lg={22} xl={20} xxl={16}>
              {data.allStrapiServices.nodes.map((node, index) => (
                <div className="section-wrapper" key={node.strapiId}>
                  <Row type="flex" justify="center" gutter={[20, 20]}>
                    <Col
                      sm={{ span: 24, order: 1 }}
                      xs={{ span: 24, order: 1 }}
                      md={{ span: 15, order: index % 2 ? 1 : 0 }}
                      lg={{ span: 16, order: index % 2 ? 1 : 0 }}
                      xl={{ span: 14, order: index % 2 ? 1 : 0 }}
                      xxl={{ span: 14, order: index % 2 ? 1 : 0 }}
                    >
                      <h2 className="row-title">{node.service_name}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.content,
                        }}
                      />

                      <div style={{ display: 'flex' }}>
                        {index === 0 ? (
                          <Link
                            to="/list-your-property"
                            className="get-report-link"
                          >
                            LIST YOUR PROPERTY
                          </Link>
                        ) : (
                          <AnchorLink
                            offset="100"
                            href="#contact-us"
                            className="get-report-link"
                          >
                            LEARN MORE
                          </AnchorLink>
                        )}
                      </div>
                    </Col>
                    <Col
                      sm={{ span: 24, order: 0 }}
                      xs={{ span: 24, order: 0 }}
                      md={{ span: 9, order: index % 2 ? 0 : 1 }}
                      lg={{ span: 8, order: index % 2 ? 0 : 1 }}
                      xl={{ span: 10, order: index % 2 ? 0 : 1 }}
                      xxl={{ span: 10, order: index % 2 ? 0 : 1 }}
                    >
                      <div
                        className="bg-image"
                        style={{
                          backgroundImage: `url('${node.thumb_photo}')`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>

          <div id="contact-us" className="contact-form">
            <Row type="flex" justify="center">
              <Col xs={23} sm={22} md={23} lg={22} xl={22} xxl={22}>
                <Row gutter={[20, 20]} align="middle" justify="center">
                  <Col
                    className="contact-content-wrap"
                    xs={{ span: 22 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 10 }}
                  >
                    <div>
                      <h4>How Can We Help?</h4>
                      <p className="contact-content">
                        Tell us your concerns and one of our young and energetic
                        team of professionals will get in touch with you. We
                        look forward to hearing from you.
                      </p>
                    </div>

                    <Divider />

                    <div>
                      <h5>Inquiries</h5>
                      <div className="icon-list-item">
                        <div>
                          <FontAwesomeIcon icon="envelope" fixedWidth />
                        </div>
                        <div>inqury@primephilippines.com</div>
                      </div>
                      <div className="icon-list-item">
                        <div>
                          <FontAwesomeIcon icon="phone-alt" fixedWidth />
                        </div>
                        <div>(+6328) 442 8888</div>
                      </div>
                    </div>

                    <br />

                    <div>
                      <h5>Careers</h5>
                      <div className="icon-list-item">
                        <div>
                          <FontAwesomeIcon icon="envelope" fixedWidth />
                        </div>
                        <div>careers@primephilippines.com</div>
                      </div>
                      <div className="icon-list-item">
                        <div>
                          <FontAwesomeIcon icon="phone-alt" fixedWidth />
                        </div>
                        <div>(+6327) 616 8554</div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    className="contact-content-wrap"
                    xs={{ span: 22 }}
                    md={{ span: 12 }}
                    lg={{ span: 10 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 8 }}
                  >
                    <div className="contact-content">
                      <ContactFormComponent />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </StyledDivWrapper>
    </Layout>
  );
}

OurExpertiseStarterPage.propTypes = {
  location: PropTypes.object,
};

export default OurExpertiseStarterPage;
